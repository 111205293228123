import { CheckIcon } from 'lucide-react';
import classNames from 'classnames';

import styles from './toast.module.scss';

interface Props {
    shown: boolean;
    message: string;
}

const Toast = ({ shown, message }: Props) => {
    return (
        <div
            className={classNames({
                [styles.toastContainer]: true,
                [styles.shown]: shown,
            })}
        >
            <div className={styles.toast}>
                <div className={styles.iconContainer}>
                    <CheckIcon size={14} />
                </div>
                <span>{message}</span>
            </div>
        </div>
    );
};

export default Toast;
