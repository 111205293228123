import classnames from 'classnames';

import styles from './description.module.scss';

const sectionClassnames = classnames({
    [styles.description]: true,
    fullContent: true,
});

const Description = () => {
    return (
        <section className={sectionClassnames}>
            <h2>
                Food you love.
                <br />
                The way you want.
            </h2>
            <h3>Explore new places, cuisines & flavors.</h3>
            <div className={styles.videosContainer}>
                <video autoPlay muted loop>
                    <source
                        type="video/mp4"
                        src="https://capacho-bucket.s3.amazonaws.com/transcoded-original-983c3232-f732-4eb0-8e01-b9140803f7fe"
                    />
                </video>

                <video autoPlay muted loop style={{ marginTop: 40 }}>
                    <source
                        type="video/mp4"
                        src="https://capacho-bucket.s3.amazonaws.com/transcoded-original-97d847a5-7d1e-4166-9477-776bb3332b50"
                    />
                </video>
            </div>
        </section>
    );
};

export default Description;
