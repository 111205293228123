import { ChangeEvent, useCallback, useState } from 'react';
import { object, string } from 'yup';
import { CircleAlertIcon } from 'lucide-react';
import classnames from 'classnames';

import Button from '../../../../components/button/button';

import styles from './contact.module.scss';

const sectionClassnames = classnames({
    [styles.contact]: true,
    fullContent: true,
});

const formSchema = object({
    name: string().min(3).required(),
    email: string().email('Email inválido').required(),
});

interface Props {
    loading: boolean;
    onSubmit: (form: any) => void;
}

const Contact = ({ loading, onSubmit }: Props) => {
    const [form, setForm] = useState({ name: '', email: '' });
    const [errorMessage, setErrorMessage] = useState('');

    const checkForm = (form: any) => {
        setErrorMessage('');
        const valid = formSchema.isValidSync(form);

        setErrorMessage(valid ? '' : 'Por favor, revisa tus datos nuevamente');
        return valid;
    };

    const clearForm = () => {
        setForm({ name: '', email: '' });
    };

    const handleSubmit = useCallback(() => {
        if (!checkForm(form)) return;
        onSubmit(form);
        clearForm();
    }, [form]);

    const handleFormInput = async (
        field: string,
        ev: ChangeEvent<HTMLInputElement>
    ) => {
        setForm((prevState) => ({
            ...prevState,
            [field]: ev.target.value,
        }));
    };

    return (
        <section className={sectionClassnames}>
            <h2>Únete a la comunidad</h2>
            <span>
                Sé de los primeros en probar Cappacho. Únete a la lista de
                espera y recibe acceso anticipado a nuestra aplicación
            </span>
            <form>
                <div className={styles.col}>
                    <label>Nombre</label>
                    <input
                        type="text"
                        placeholder="Nombre"
                        value={form.name}
                        onChange={(ev) => handleFormInput('name', ev)}
                    />
                </div>
                <div className={styles.col}>
                    <label>Email</label>
                    <input
                        type="email"
                        placeholder="Email"
                        value={form.email}
                        onChange={(ev) => handleFormInput('email', ev)}
                        autoCapitalize="true"
                    />
                </div>
                {errorMessage && (
                    <p className={styles.error}>
                        <CircleAlertIcon size={20} />
                        {errorMessage}
                    </p>
                )}

                <div style={{ display: 'flex', alignSelf: 'center' }}>
                    <Button onClick={handleSubmit} loading={loading}>
                        Únete a la lista de espera
                    </Button>
                </div>
            </form>
        </section>
    );
};

export default Contact;
