import styles from './footer.module.scss';

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <span className={styles.divider} />
            <p>© 2024 Cappacho. Todos los derechos reservados</p>
        </footer>
    );
};

export default Footer;
