import { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import Highlight from '../../../../assets/img/highlight.svg';

import styles from './hero.module.scss';

const MainTitle = () => {
    const titleRef = useRef<HTMLHeadingElement>(null);
    const [isHighlighted, setIsHighlighted] = useState(false);

    useEffect(() => {
        if (!titleRef.current) return;
        const observer = startObserver(titleRef.current);

        return () => {
            titleRef.current && observer.unobserve(titleRef.current);
        };
    }, [titleRef]);

    const startObserver = (element: HTMLElement) => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1,
        };

        const observer = new IntersectionObserver((entries) => {
            const [entry] = entries;
            if (!entry.isIntersecting) return;
            setIsHighlighted(true);
        }, options);

        observer.observe(element);
        return observer;
    };

    return (
        <h1 ref={titleRef} className={styles.mainTitle}>
            Stop the noise.
            <br />
            Focus on what{' '}
            <span className={styles.highlightedContent}>
                <div
                    className={classnames({
                        [styles.highlightContainer]: true,
                        [styles.isHighlighted]: isHighlighted,
                    })}
                >
                    <img
                        className={styles.highlight}
                        src={Highlight}
                        alt="highlight"
                    />
                </div>
                matters.
            </span>
        </h1>
    );
};

export default MainTitle;
