import React from 'react';
import { Route, Routes } from 'react-router';

import styles from './app.module.scss';
import HomePage from './pages/home/home.page';

function App() {
    return (
        <main id={styles.app}>
            <Routes>
                <Route path="/" element={<HomePage />} />
            </Routes>
        </main>
    );
}

export default App;
