import classnames from 'classnames';

import MockImage from '../../../../assets/img/mock.png';
import MainNav from '../../../../components/main-nav/main-nav';

import styles from './hero.module.scss';
import MainTitle from './main-title';
import { useEffect, useState } from 'react';

const sectionClassnames = classnames({
    [styles.hero]: true,
    fullContent: true,
});

const Hero = () => {
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        window.addEventListener('scroll', handelScroll);

        return () => window.removeEventListener('scroll', handelScroll);
    }, []);

    const handelScroll = () => setScrollY(window.scrollY);

    return (
        <section className={sectionClassnames}>
            <MainNav />
            <MainTitle />
            <div className={styles.heroImageContainer}>
                <img
                    className={styles.heroImage}
                    src={MockImage}
                    alt="mock"
                    width="320"
                    style={{ transform: `translateY(-${scrollY * 0.5}px)` }}
                />
            </div>
        </section>
    );
};

export default Hero;
